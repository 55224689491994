<!--  -->
<template>
  <div>
    <blockTitle title="密钥"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="sysSecretKey"
            :tableColumns="tableColumns"
        >
          <template #left-operator>
            <el-button @click="createSecret" size="small" type="primary"
            >创建密钥
            </el-button>
          </template>
          <template #cloudType="{ row }">
            {{ row.cloudFactoryName }}-{{ row.cloudFactoryProductName }}
          </template>
          <template #accessSecretKey>
            <el-tooltip
                class="item"
                effect="dark"
                content="无法查看"
                placement="top"
            >
              <span>****************</span>
            </el-tooltip>
          </template>
          <template #operator="{ row }">
            <el-button @click="deleteRow(row)" size="mini" type="danger"
            >删除
            </el-button>
          </template>
        </AppTable>
      </div>
    </el-card>
    <addSecretDialog
        @on-submit="refreshTable"
        ref="addSecretDialog"
    ></addSecretDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.card-content {
  height: calc(100vh - 180px);
}
</style>
<script type="text/ecmascript-6">
import addSecretDialog from '@/components/bucket/addSecretDialog'

export default {
  data () {
    return {
      tableColumns: [
        {
          label: '密钥名称',
          prop: 'secretKeyName'
        },
        {
          label: 'accessKeyId',
          prop: 'accessKeyId'
        },
        {
          label: 'AccessSecretKey',
          type: 'customSlot',
          slotName: 'accessSecretKey'
        },
        {
          label: '操作',
          type: 'customSlot',
          slotName: 'operator'
        }
      ]
    }
  },
  components: {
    addSecretDialog
  },
  methods: {
    createSecret () {
      this.$refs.addSecretDialog.show()
    },
    refreshTable () {
      this.$refs.AppTable.refresh()
    },
    deleteRow (row) {
      this.$api.delete('sysSecretKey', row.id).then(res => {
        this.refreshTable()
      })
    }
  },
  mounted () {
  }
}
</script>
